.locationBlock{
    color: var(--primary-text-color);
}

iframe{
    min-width: 5em;
    min-height: 30em;
    width: 100%;
    height: 100%;
}

.locationList{
    list-style-type: none;
    padding-top: 10px;
}

.locationTitle{
    padding-left: 40px;
}

.locationSection{
    margin-top: 2em;
}

.locationSection div{
    padding: 2em;
}

.locationLinkIlevia{
    border: solid var(--primary-dark-color) 3px;
    transition: transform .2s;
    text-decoration: none;
    border-radius: 15px;
    margin-left: 40px;
    font-size: 20px;
    padding:10px;
    color: black;
}

.locationLinkIlevia:hover{
    background-color: var(--primary-dark-color);
    transform: scale(1.1);
    color:white;
}

@media only screen and (max-width: 576px) {
    .locationBlock iframe{
        min-width: 10em;
        width: 100%;
    }

    .locationBlock{
        margin-left: 0;
        margin-right: 0;
    }

    .locationSection{
        padding-left:0;
    }

    div.locationItem {
        padding: 0;
    }

    .locationTitle{
        padding-left: 5px;
        padding-top: 30px;
    }

    .locationLine{
        margin-left: 5px;
    }

    .locationList{
        padding-left: 5px;
    }

    .locationLinkIlevia{
        font-size: 1.2em;
        margin-bottom: 10px;
        margin-top: 10px;
    }
}