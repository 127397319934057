#page_planning div.align-center-web.row {
    padding-bottom: 2em;
}

#page_planning h3{
    margin-left: 1em;
}

div.planning-card-overview.bg-image{
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: right center;
    height: 100%;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 1em;
}

div.planning-card-overview p {
    font-weight: bold;
    margin-left: 0.5em;
}
div.planning-card-overview svg {
    width: 1.25em;
    height: 1.25em;
    margin-right: 1em;
}

div.planning-card {
    border-radius: 1em;
    background-color: #FFFFFF;
    margin-bottom: 2em;
    transition: transform 250ms;
}

div.planning-card:hover {
    transform: translateY(-10px);
}

div.planning-card div.planning-card-overview{
    padding: 1em;
    height: 9em;
    color: #FFFFFF;
}

div.planning-card div.planning-card-description{
    padding: 1em;
}

.test-card {
    transition        : all .3s ease;
    -webkit-transition: all .3s ease;
}

.test-card--disapear {
    display: none;
}

.test-card--disapear.displayed {
    animation: scale-display .3s;
    display: block;
}

.test-card--disapear.out {
     animation: scale-display--reversed .3s;
     animation-fill-mode:forwards;
     display: none;
 }

@keyframes scale-display {
    0% {
        opacity: 0;
        transform: scale(0);
        -webkit-transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}


@keyframes scale-display {
    0% {
        opacity: 0;
        transform: scale(0);
        -webkit-transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

@keyframes scale-display--reversed {
    0% {
        display: block;
        opacity: 1;
        transform: scale(1);
        -webkit-transform: scale(1);
    }
    50% {
        display: inline-flex;
        opacity: 1;
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    99% {
        display: inline-flex;
        opacity: 0;
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    100% {
        display: none;
        opacity: 0;
        transform: scale(0);
        -webkit-transform: scale(0);
    }
}

div.planning-card-description span.badge {
    background-color: #860000;
    margin-left: 1em;
    text-align: justify;
}

button.displayInMobileVersion {
    display: none;
}

.displayInMobileVersion {
    appearance: none;
    background-color: #f7f6f6;
    border-width: 0;
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    display: inline-block;
    font-family: Clarkson,Helvetica,sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1em;
    margin: 0;
    opacity: 1;
    outline: 0;
    padding: 1.5em 2.2em;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-rendering: geometricprecision;
    text-transform: uppercase;
    transition: opacity 300ms cubic-bezier(.694, 0, 0.335, 1),background-color 100ms cubic-bezier(.694, 0, 0.335, 1),color 100ms cubic-bezier(.694, 0, 0.335, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;
}

.displayInMobileVersion:before {
    animation: opacityFallbackOut .5s step-end forwards;
    backface-visibility: hidden;
    background-color: #EBEBEB;
    clip-path: polygon(-1% 0, 0 0, -25% 100%, -1% 100%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateZ(0);
    transition: clip-path .5s cubic-bezier(.165, 0.84, 0.44, 1), -webkit-clip-path .5s cubic-bezier(.165, 0.84, 0.44, 1);
    width: 100%;
}

.displayInMobileVersion:hover:before {
    animation: opacityFallbackIn 0s step-start forwards;
    clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
}

.displayInMobileVersion:after {
    background-color: #FFFFFF;
}

.displayInMobileVersion span {
    z-index: 1;
    position: relative;
}
@media only screen and (max-width: 816px) {
    .showMoreRow {
        height: 32em;
        overflow-y: hidden;
    }

    .showLessRow {
        height: 100%;
        overflow-y: visible;
    }

    button.displayInMobileVersion {
        display: block;
    }
}