/*--Footer--*/
.partners h2{
    color: white;
    padding-top: 1em;
    padding-bottom: 0.9em;
}

.partners a:hover, .partners a:visited, .partners a:active{
    color: var(--primary-text-color);
    text-decoration: none;
}

footer li.col {
    margin-bottom: 1.3em;
}

.footerIcon{
    border-radius: 10em;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
}

/**Link Item**/
.linkItem {
    text-decoration: none;
    color: #FFFFFF;
}

.linkItem:hover {
    padding: 1em;
    border: white 0.2em solid;
    color: white;
    border-radius: 1em;
    text-decoration: none;
    cursor: pointer;
}

/**Comment**/
.footerComment{
    color: white;
    font-size: 20px;
    margin-bottom: 15px;
    text-align: center;
}



@media only screen and (max-width: 576px) {
    /**Link list**/
    .footerLinksList{
        display: none;
    }

    .list-unstyled.text-center.row .col{
        margin-bottom: 1em;
    }

    #footer_links.col-md-9.text-center.mt-5{
        margin-top: 0 !important;
    }
}

