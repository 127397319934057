.sponsorCard{
    background-color: white;
    border-radius:10px;
    transition: transform .2s;
}
.sponsorCard:hover{
    transform: scale(1.1);
}
.sponsorCard img{
    margin-top: 10px;
    height:75px;
    width:75px;
}

.sponsorCardTitle{
    text-align: center;
}

.sponsorCardText{
    text-align: justify;
    margin-top: 15px;
    font-size: 17px;
}

.sponsorRow{
    margin:10px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 576px) {
    .sponsorRow{
        margin:0;
    }

    .col.sponsorCardTitle{
        margin-top: 1em;
    }

    #page_partenaires .rounded-card-white img{
        width: 60%;
    }
}