/*--NAVBAR--*/
nav{
    margin-top: 1em;
    margin-bottom: 1em;
}

img.shield{
    height: 4em;
}

img.logo{
    margin-left: 0.6em;
    height: 4em;
}
.hr{
    border: 0.1em solid var(--primary-grey-color);
    border-radius: 0.1em;
}
hr.hr-NavBar{
    margin: 0em 10em 2em 10em;
}

.active{
    align-items: center;
    background-color: var(--primary-red-color);
    border: 0;
    border-radius: 1em;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-size: 1em;
    text-decoration: none;
    justify-content: center;
    line-height: 2em;
    max-width: 15em;
    min-height: 2em;
    min-width: 0em;
    overflow: hidden;
    padding: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    margin-left: 2em;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.active:hover,
.active:focus {
    text-decoration: none;
    background-color: var(--primary-dark-color);
    color: #ffffff;
}

.active:active {
    text-decoration: none;
    background: var(--primary-dark-color);
    color: rgb(255, 255, 255);
}

.inactive{
    align-items: center;
    background-color: var(--primary-text-light-color);
    border: 0;
    border-radius: 1em;
    box-sizing: border-box;
    /*border-left: 0.2em solid #e1e0e0;*/
    color: #aaaaaa;
    cursor: pointer;
    display: inline-flex;
    font-size: 1em;
    justify-content: center;
    line-height: 2em;
    overflow: hidden;
    padding: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    margin-left: 2em;
    text-align: center;
    vertical-align: middle;
}

.inactive:hover,
.inactive:focus {
    /*
    text-decoration: none;
    color: #494141;
     */
    text-decoration: none;
    background: var(--primary-dark-color);
    color: rgb(255, 255, 255);
}

.inactive:active {
    /*
    text-decoration: none;
    color: #494141;
     */
    text-decoration: none;
    background: var(--primary-dark-color);
    color: rgb(255, 255, 255);
}

@media only screen and (max-width: 576px) {
    header.align-center-web{
        margin: 0;
    }

    .navbar-brand{
        margin-right: 0;
    }

    img.shield{
        height: 3em;
    }

    img.logo{
        height: 3em;
    }
}