.titleBlock{
    background-color: var(--primary-bg-color);
    margin-bottom: 6em;
}

.titleBlockTitle{
    font-size: 3.5em;
    font-family: 'God of War';
    color: var(--primary-dark-color);
}

.titleBlockLine{
    background-color: var(--primary-grey-color);
    margin-left: 10px;
    margin-right: 10px;
    height: 2px;
}


@media only screen and (max-width: 576px) {
    .titleBlock{
        margin-bottom: 2.5em;
    }
    .titleBlockTitle{
        font-size: 2em;
    }
}