.pageBlock{
    transition: transform .2s;
    background-color: var(--primary-bg-color);
    border-radius:15px;
    margin:15px;
    padding-bottom: 30px;
}

.wrapper-presentationBlockImage{
    text-align: center;
}

.presentationBlockText{
    font-size: 1.1em;
    text-align: justify;
}

.presentationBlockImage{
    width: 100%;
    border-radius: 1em;
}


@media only screen and (max-width: 576px) {
    .pageBlock{
        margin-left: 0;
        margin-right: 0;
    }

    .presentationBlockText{
        padding: 1em 0;
        font-size: 1em;
    }
}