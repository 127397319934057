img.whereToSleepImage{
    max-height: 20em;
}

.whereToSleepBlock{
    margin-top: 3em;
    margin-bottom: 3em;
    color: var(--primary-text-color);
}

.whereToSleepItem{
    border: 1em solid white;
}

.whereToSleepItem h3 {
    margin-top: 1em;
    color: var(--primary-text-color);
}

.whereToSleepItem a:hover, .whereToSleepItem a:active, .whereToSleepItem a:visited{
    text-decoration: none;
}

@media only screen and (max-width: 576px) {
    .whereToSleepBlock .rounded-card-white{
        padding: 0;
    }

    .whereToSleepItem{
        margin: 0.25em ;
    }
}

