.contactBlock{
    /*
    position: relative;
    background:linear-gradient(var(--primary-bg-color),var(--primary-dark-color));
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 15px 15px 75px;
    margin: 15px 15px 0 15px;
    color: white;
     */
    color: var(--primary-dark-color);
}

.contactTitle{
    margin-bottom: 1em;
}

.contactBlockLine{
    background-color: white;
    height: 2px;
    width: 250px;
    margin-bottom: 20px;
}

.wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.wave svg {
    position: relative;
    display: block;
    width: calc(115% + 1.3px);
    height: 101px;
}

.wave .shape-fill {
    fill: #FFFFFF;
}

@media only screen and (max-width: 576px) {

    .contactBlock{
        margin-left: 0;
        margin-right: 0;
    }

    .contactTitle{
        margin-left: 5px;
    }
}