:root{
    --primary-text-color:#494141;
    --primary-text-light-color:#f7f6f6;
    --primary-link-color:#bf0000;
    --primary-dark-color:#860000;
    --primary-red-color: #960018;
    --primary-bg-color:#f7f6f6;
    --primary-grey-color:#e1e0e0;
}
@font-face {
    font-family: 'God of War';
    src: local('God of War'), url("fonts/GODOFWAR.TTF") format('truetype');
}

div#App{
    background-color: var(--primary-bg-color);
    overflow-x: hidden;
}

header{
    overflow: hidden;
}

h2{
    color: var(--primary-dark-color);
    margin-bottom: 0.5em;
}

p{
    text-align: justify;
}

a{
    text-decoration: none !important;
}

a>h2{
    color: var(--primary-dark-color);
    margin-bottom: 0.5em;
    transition: transform .2s;
}

a>h2:hover{
    text-decoration: none;
    transform: scale(1.2);
}

.page{
    background-color: var(--primary-bg-color);
    padding-top: 5px;
    padding-bottom: 15px;
}

.bg-grey{
    background-color: #f7f6f6;
}

.bg-dark-red{
    background-color: #860000;
    color: #FFFFFF;
}

.border-dark-red{
    border: #860000 solid 0.12em;
}

.rounded-card-white{
    background-color: #FFFFFF;
    border-radius: 1em;
    padding: 2em;
    margin: 2em 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.rounded-card-white img{
    width: 100%;
    border-radius: 0.5em;
}

.align-center-web{
    margin-left: 5em;
    margin-right: 5em;
}

.scaleTransitionImage{
    transition: transform .2s;
}

.scaleTransitionImage:hover{
    transform: scale(1.2);
}

.transports{
margin-right: 0.5em;
}

.M1 {
    background-color:#FDC41F;
    color:#000000;
}

.M2 {
    background-color:#E30613;
    color:#FFFFFF;
}

.L5 {
    background: #0080C9 ;
    color: #FFFFFF;
}

@media only screen and (max-width: 576px){
    .align-center-web{
        margin-left: 2em;
        margin-right: 2em;
    }

    h2{
        font-size: 1.7em;
    }

    h3{
        font-size: 1.4em;
    }
}