.instagramPostRow{
    margin: 3em 0 3em 0;
}

.instagramPost{
    color: var(--primary-text-color);
    border: solid transparent 2px;
    background-color: var(--primary-bg-color);
    border-radius: 1em;
    transition: transform .3s;
}

.instagramPost .row:hover{
    background-color: rgba(134, 0, 0, 0.37);
    color: #FFFFFF;
}

.instagramPost:hover{
    color: var(--primary-text-color);
}

a:hover, a:visited{
    text-decoration: none;
}

.instagramPostImage{
    height: 12em;
}

@media only screen and (max-width: 576px) {
    .instagramPostImageWrapper{
        padding: 0;
    }

    .instagramPostImage{
        width: 100%;
        height: 100%;
    }
}